import {
  Home as HomeIcon,
  Item,
  ShoppingCart as ShoppingCartIcon,
  UserCircle as UserCircleIcon,
  Users as UsersIcon
} from '@inappsis/react-inappsis-ui';
import {
  AccountTree as AccountTreeIcon,
  ArticleRounded as ArticleIcon,
  BallotRounded as BallotIcon,
  ConfirmationNumberRounded as ConfirmationNumberIcon,
  DateRange as DateRangeIcon,
  Inventory2Rounded as InventoryIcon,
  ManageAccountsRounded as ManageAccountsIcon,
  AttachMoney as MoneyIcon,
  NextWeek as NextWeekIcon,
  PeopleOutlineRounded as PeopleOutlineRoundedIcon,
  School as SchoolIcon,
  MiscellaneousServicesOutlined as ServicesIcon
} from '@mui/icons-material';
import type { TFunction } from 'react-i18next';

export const dashboardItem = (t: TFunction): Item => ({
  title: t('layout.general.dashboard'),
  path: '/dashboard',
  icon: <HomeIcon fontSize="small" />
});

export const accountItem = (t: TFunction): Item => ({
  title: t('layout.general.account'),
  path: '/profiles/details',
  icon: <UserCircleIcon fontSize="small" />
});

export const clientItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.clients'),
  path: '/clients',
  icon: <UsersIcon fontSize="small" />
});

export const employeeItem = (t: TFunction, onlyBirthdays: boolean): Item => {
  const childrenSections = [
    {
      title: t('layout.managementSection.employeesBirthdays'),
      path: '/employees/birthdays'
    }
  ];
  if (!onlyBirthdays) {
    childrenSections.unshift({
      title: t('layout.managementSection.employeesList'),
      path: '/employees'
    });
  }
  return {
    title: t('layout.managementSection.employees'),
    path: '/employees',
    icon: <PeopleOutlineRoundedIcon fontSize="small" />,
    children: childrenSections
  };
};

export const providerItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.providers'),
  path: '/providers',
  icon: <ManageAccountsIcon fontSize="small" />
});

export const opportunityItem = (t: TFunction, withDashboard: boolean): Item => {
  let childrenSections: Item[] = [];
  if (withDashboard) {
    childrenSections = [
      {
        title: t('layout.managementSection.opportunities.dashboard'),
        path: '/opportunities/dashboard'
      },
      {
        title: t('layout.managementSection.opportunities.list'),
        path: '/opportunities'
      },
      {
        title: t('layout.managementSection.opportunities.kanban'),
        path: '/opportunities/kanban'
      }
    ];
  }

  return {
    title: t('layout.managementSection.opportunities.opportunities'),
    path: '/opportunities',
    icon: <NextWeekIcon fontSize="small" />,
    children: childrenSections.length ? childrenSections : undefined
  };
};

export const trainingItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.trainings'),
  path: '/trainings',
  icon: <SchoolIcon fontSize="small" />
});

export const presaleItem = (t: TFunction, withDashboard: boolean): Item => {
  const childrenSections = [
    {
      title: t('layout.managementSection.presales.list'),
      path: '/presales'
    },
    {
      title: t('layout.managementSection.presales.nextToRemission'),
      path: '/presales/on-eval'
    }
  ];

  if (withDashboard) {
    childrenSections.unshift({
      title: t('layout.managementSection.presales.dashboard'),
      path: '/presales/dashboard'
    });
  }
  return {
    title: t('layout.managementSection.presales.presales'),
    path: '/presales',
    icon: <ConfirmationNumberIcon fontSize="small" />,
    children: childrenSections
  };
};

export const projectItem = (
  t: TFunction,
  onlyRemission: boolean,
  onlyProjects: boolean
): Item => {
  const childrenSections = [
    ...(!onlyProjects
      ? [
          {
            title: t('layout.managementSection.projects.remissions'),
            path: '/projects/remissions/'
          }
        ]
      : []),
    ...(!onlyRemission
      ? [
          {
            title: t('layout.managementSection.projects.list'),
            path: '/projects'
          },
          {
            title: t('layout.managementSection.projects.finished'),
            path: '/projects/finished'
          }
        ]
      : [])
  ];

  return {
    title: t('layout.managementSection.projects.projects'),
    icon: <AccountTreeIcon fontSize="small" />,
    path: '/projects',
    children: childrenSections
  };
};

export const purchaseItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.purchases'),
  path: '/purchases',
  icon: <ShoppingCartIcon fontSize="small" />
});

export const referencePriceItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.referencePrices'),
  path: '/purchases/reference-prices',
  icon: <MoneyIcon fontSize="small" />
});

export const documentItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.qualityDocumentation'),
  path: '/documents',
  icon: <ArticleIcon fontSize="small" />
});

export const inventoryItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.inventory.inventory'),
  path: '/inventories',
  icon: <InventoryIcon fontSize="small" />,
  children: [
    {
      title: t('layout.managementSection.inventory.inventory'),
      path: '/inventories'
    },
    {
      title: t('layout.managementSection.inventory.remissions'),
      path: '/inventories/remissions'
    }
  ]
});

export const hrRequestItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.hrRequests.requests'),
  icon: <BallotIcon fontSize="small" />,
  path: '/hr-requests',
  children: [
    {
      title: t('layout.managementSection.hrRequests.timeoff'),
      path: '/hr-requests/timeoff'
    },
    {
      title: t('layout.managementSection.hrRequests.documents'),
      path: '/hr-requests/documents'
    },
    {
      title: t('layout.managementSection.hrRequests.financial'),
      path: '/hr-requests/financial'
    }
  ]
});

export const reportItem = (t: TFunction): Item => ({
  title: t('layout.reportsSection.assignations'),
  path: '/reports/assignations',
  icon: <DateRangeIcon fontSize="small" />
});

export const timeoffReportItem = (t: TFunction): Item => ({
  title: t('layout.reportsSection.timeoff'),
  path: '/reports/timeoff',
  icon: <DateRangeIcon fontSize="small" />
});

export const presalesReportItem = (t: TFunction): Item => ({
  title: t('layout.reportsSection.presales'),
  path: '/reports/presales',
  icon: <DateRangeIcon fontSize="small" />
});

export const serviceItem = (t: TFunction): Item => {
  const childrenSections = [
    {
      title: t('layout.managementSection.activeServices'),
      path: '/services'
    },
    {
      title: t('layout.managementSection.history'),
      path: '/services/history'
    }
  ];
  return {
    title: t('layout.managementSection.services'),
    path: '/services',
    icon: <ServicesIcon fontSize="small" />,
    children: childrenSections
  };
};
