export const COMMON_CACHE_KEYS = {
  countries: 'countries',
  states: 'states',
  cities: 'cities',
  companies: 'companies',
  banks: 'banks',
  ciiucodes: 'ciiucodes'
};

export const THIRTY_SECONDS_IN_MS = 30 * 1000;
export const A_MINUTE_IN_MS = 60 * 1000;
export const FIVE_MINUTES_IN_MS = A_MINUTE_IN_MS * 5;
export const TEN_MINUTES_IN_MS = A_MINUTE_IN_MS * 10;
export const TWENTY_MINUTES_IN_MS = A_MINUTE_IN_MS * 20;
export const THIRTY_MINUTES_IN_MS = A_MINUTE_IN_MS * 30;

export const FORMAT_PDF_DOC_EXCEL = ['.xls', '.xlsx', '.pdf', '.doc', '.docx'];
export const ALL_FORMAT = [
  '.xls',
  '.xlsx',
  '.pdf',
  '.doc',
  '.docx',
  '.png',
  '.jpg',
  '.jpeg',
  '.svg'
];
export const FORMAT_IMAGE_PDF = ['.jpg', '.png', '.jpeg', '.pdf'];
export const FORMAT_IMAGE_PDF_DOC = [
  '.jpg',
  '.png',
  '.jpeg',
  '.pdf',
  '.doc',
  '.docx'
];
export const FORMAT_IMAGES = ['.jpg', '.png', '.jpeg'];

export const DEFAULT_AVATAR =
  '/static/mock-images/avatars/avatar-placeholder.png';

export const DEFAULT_USER_NAME = 'Test User';
export const BANNER_IMAGE = '/static/mock-images/banner-illustration.png';
export const ROLES_WITH_DASHBOARD: Role[] = ['ADMIN', 'PRESALES_MANAGER'];
