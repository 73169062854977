import {
  Item,
  LayoutRoot,
  Navbar,
  Section,
  Sidebar,
  SidebarSection
} from '@inappsis/react-inappsis-ui';
import { Box, Toolbar } from '@mui/material';
import { AccountButton, LanguageButton } from 'layouts/NavbarElements';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { checkRoles } from 'shared/utils/functions';
import {
  adminSection,
  commercialSection,
  coordinatorSection,
  documentSection,
  financialSection,
  generalSections,
  hrAssistantSection,
  hrRequestsSection,
  hrSection,
  operationalSection,
  presaleManagerSection,
  presaleSection,
  purchaseSection,
  reportSection,
  technicianSection,
  timeoffReportSection
} from './LayoutSections';
import { Logo } from './Logo';

interface LayoutProps {
  children?: ReactNode;
}

const removeDuplicateItemHandler = (
  sections: Section[],
  currentSections: Section[]
) => {
  return currentSections.map((currentSection) => {
    currentSection.items.forEach((currentItem: Item, index: number) => {
      const duplicate = sections.find((section) =>
        section.items.find((item: Item) => item.title === currentItem.title)
      );
      if (duplicate) currentSection.items.splice(index, 1);
    });
    return currentSection;
  });
};

const adminNavConfig = (t: TFunction): Section[] => [
  generalSections(t),
  adminSection(t),
  reportSection(t, false),
  hrRequestsSection(t)
];

const hrNavConfig = (t: TFunction): Section[] => [hrSection(t)];

const coordinatorNavConfig = (t: TFunction): Section[] => [
  coordinatorSection(t)
];

const presaleManagerNavConfig = (t: TFunction): Section[] => [
  presaleManagerSection(t)
];

const financialNavConfig = (t: TFunction): Section[] => [financialSection(t)];

const presaleNavConfig = (t: TFunction): Section[] => [presaleSection(t)];
const technicianNavConfig = (t: TFunction): Section[] => [technicianSection(t)];

const operationalNavConfig = (t: TFunction): Section[] => [
  operationalSection(t),
  reportSection(t, true)
];

const commercialNavConfig = (t: TFunction): Section[] => [commercialSection(t)];
const purchaseNavConfig = (t: TFunction): Section[] => [purchaseSection(t)];
const hrAssistantNavConfig = (t: TFunction): Section[] => [
  hrAssistantSection(t),
  timeoffReportSection(t)
];

export const Layout: FC<LayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();
  const sections = useMemo(() => {
    if (checkRoles(['ADMIN'])) return adminNavConfig(t);

    // Set section array based on user role
    const currentSections: Section[] = [];
    currentSections.push(generalSections(t));
    if (checkRoles(['HR'])) currentSections.push(...hrNavConfig(t));
    if (checkRoles(['COORDINATOR'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, coordinatorNavConfig(t))
      );
    }
    if (checkRoles(['PRESALES_MANAGER'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(
          currentSections,
          presaleManagerNavConfig(t)
        )
      );
    }
    if (checkRoles(['FINANCIAL'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, financialNavConfig(t))
      );
    }
    if (checkRoles(['PRESALES'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, presaleNavConfig(t))
      );
    }
    if (checkRoles(['TECHNICIAN'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, technicianNavConfig(t))
      );
    }
    if (checkRoles(['OPERATIONAL_MANAGER'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, operationalNavConfig(t))
      );
    }
    if (checkRoles(['COMMERCIAL_MANAGER'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, commercialNavConfig(t))
      );
    }
    if (checkRoles(['PURCHASES'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, purchaseNavConfig(t))
      );
    }
    if (checkRoles(['HR_ASSISTANT'])) {
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, hrAssistantNavConfig(t))
      );
    }
    currentSections.push(hrRequestsSection(t));
    if (!checkRoles(['TECHNICIAN']))
      currentSections.push(
        ...removeDuplicateItemHandler(currentSections, [documentSection(t)])
      );

    return currentSections;
  }, [t]);

  return (
    <>
      <LayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {children}
        </Box>
      </LayoutRoot>
      <Navbar onOpenSidebar={(): void => setIsSidebarOpen(true)}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          {/* Others navbar buttons should be added here */}
          <LanguageButton />
          <AccountButton />
        </Toolbar>
      </Navbar>
      <Sidebar
        logo={
          <Logo
            sx={{
              maxHeight: 90,
              maxWidth: '100%'
            }}
            variant="light"
          />
        }
        onClose={(): void => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      >
        {sections.map((section) => (
          <SidebarSection
            key={section.title}
            path={location.pathname}
            sx={{
              mt: 2,
              '& + &': {
                mt: 2
              }
            }}
            {...section}
          />
        ))}
      </Sidebar>
    </>
  );
};
