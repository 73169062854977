import { ThemeOptions } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';

enum THEMES {
  LIGHT = 'light',
  DARK = 'dark'
}

enum COLORS {
  PRIMARY = '#642596',
  // SECONDARY = '#606162'
  SECONDARY = '#642596'
}

interface Settings {
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  mode: THEMES;
  customThemeOptions?: ThemeOptions;
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

const initialSettings: Settings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  mode: THEMES.LIGHT,
  customThemeOptions: {
    palette: {
      primary: {
        main: COLORS.PRIMARY
      },
      secondary: {
        main: COLORS.SECONDARY
      }
    }
  }
};

export const restoreSettings = (): Settings | null => {
  let settings: Settings | null = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');
    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        direction: 'ltr',
        responsiveFontSizes: true,
        mode: window.matchMedia('(prefers-color-scheme: dark)').matches
          ? THEMES.DARK
          : THEMES.LIGHT,
        customThemeOptions: {
          palette: {
            primary: {
              main: COLORS.PRIMARY
            },
            secondary: {
              main: COLORS.SECONDARY
            }
          }
        }
      };
    }
  } catch (err) {
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }
  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

export const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => {
    // empty
  }
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<Settings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
