import { useUserContext } from 'hooks/useUserContext';
import { FC, Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from 'shared/components/Auth/AuthGuard';
import { Layout as MainLayout } from './layouts/Layout';
import { GuestGuard, LoadingScreen, RolesGuard } from './shared/components';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: FC;
  layout?: FC;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    guard: AuthGuard,
    path: '/',
    component: () => <Navigate to="/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/NotFound'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/pages/Authentication/Login'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('./views/pages/Authentication/ForgotPassword'))
  },
  {
    exact: true,
    path: '/reset-password/:token',
    component: lazy(() => import('./views/pages/Authentication/ResetPassword'))
  },
  {
    path: '/clients/*',
    guard: RolesGuard([
      'ADMIN',
      'PRESALES_MANAGER',
      'PRESALES',
      'FINANCIAL',
      'COMMERCIAL_MANAGER'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Clients/ClientList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Clients/Form/ClientForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Clients/Form/ClientForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Clients/Details/ClientDetails')
        )
      },
      {
        exact: true,
        path: '/:clientId/contacts/create',
        component: lazy(
          () => import('./views/pages/Clients/Form/ClientContactForm')
        )
      },
      {
        exact: true,
        path: '/:clientId/contacts/:id/edit',
        component: lazy(
          () => import('./views/pages/Clients/Form/ClientContactForm')
        )
      },
      {
        exact: true,
        path: '/documents/:clientId/create',
        component: lazy(
          () =>
            import(
              './views/pages/Clients/Details/Documents/Form/ClientDocumentForm'
            )
        )
      },
      {
        exact: true,
        path: '/documents/:clientId/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Clients/Details/Documents/Form/ClientDocumentForm'
            )
        )
      }
    ]
  },
  {
    exact: true,
    path: '/dashboard',
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import('./views/pages/Dashboard'))
  },
  {
    path: '*',
    guard: GuestGuard,
    routes: [
      {
        component: () => <Navigate to="/404" />
      }
    ]
  },
  {
    exact: true,
    guard: RolesGuard(['ADMIN', 'HR_ASSISTANT']),
    path: '/trainings/*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Trainings/TrainingList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/Trainings/Form/TrainingForm')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/Trainings/Form/TrainingForm')
        )
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Trainings/Details/TrainingDetails')
        )
      },
      {
        exact: true,
        path: '/:trainingId/attendees/create',
        component: lazy(
          () => import('./views/pages/Trainings/Form/TrainingAttendeeForm')
        )
      },
      {
        exact: true,
        path: '/:trainingId/attendees/:id/edit',
        component: lazy(
          () => import('./views/pages/Trainings/Form/TrainingAttendeeForm')
        )
      }
    ]
  },
  {
    path: '/employees/*',
    guard: RolesGuard([
      'ADMIN',
      'HR',
      'HR_ASSISTANT',
      'OPERATIONAL_MANAGER',
      'FINANCIAL'
    ]),
    layout: MainLayout,
    routes: [
      {
        path: '/',
        guard: RolesGuard([
          'ADMIN',
          'HR',
          'HR_ASSISTANT',
          'OPERATIONAL_MANAGER',
          'COORDINATOR',
          'PURCHASES',
          'FINANCIAL'
        ]),
        component: lazy(() => import('./views/pages/Employees/EmployeeList'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/birthdays',
        component: lazy(
          () => import('./views/pages/Employees/Birthdays/Birthdays')
        )
      },
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/Employees/Form/EmployeeForm')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/Employees/Form/EmployeeForm')
        )
      },
      {
        exact: true,
        guard: RolesGuard([
          'ADMIN',
          'HR',
          'HR_ASSISTANT',
          'OPERATIONAL_MANAGER',
          'COORDINATOR',
          'PURCHASES',
          'FINANCIAL'
        ]),
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Employees/Details/EmployeeDetails')
        )
      },
      {
        exact: true,
        path: '/social-security-sheets/:employeeId/create',
        component: lazy(
          () =>
            import(
              './views/pages/Employees/Details/SocialSecuritySheet/EmployeeSocialSecuritySheetForm'
            )
        )
      },
      {
        exact: true,
        path: '/social-security-sheets/:employeeId/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Employees/Details/SocialSecuritySheet/EmployeeSocialSecuritySheetForm'
            )
        )
      },
      {
        exact: true,
        path: '/documents/:employeeId/create',
        component: lazy(
          () =>
            import(
              './views/pages/Employees/Details/Documents/Form/EmployeeDocumentForm'
            )
        )
      },
      {
        exact: true,
        path: '/documents/:employeeId/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Employees/Details/Documents/Form/EmployeeDocumentForm'
            )
        )
      },
      {
        exact: true,
        path: ':employeeId/contracts/create',
        component: lazy(
          () =>
            import('./views/pages/Employees/Details/Contracts/EmployeeContract')
        )
      },
      {
        exact: true,
        path: ':employeeId/contracts/:id/edit',
        component: lazy(
          () =>
            import('./views/pages/Employees/Details/Contracts/EmployeeContract')
        )
      }
    ]
  },
  {
    path: '/providers/*',
    guard: RolesGuard(['ADMIN', 'PURCHASES', 'FINANCIAL']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Providers/ProviderList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/Providers/form/ProviderForm')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/Providers/form/ProviderForm')
        )
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Providers/Details/ProviderDetails')
        )
      },
      {
        exact: true,
        path: '/payment-account/:providerId/create',
        component: lazy(
          () =>
            import('./views/pages/Providers/form/ProviderPaymentAccountForm')
        )
      },
      {
        exact: true,
        path: '/payment-account/:providerId/:id/edit',
        component: lazy(
          () =>
            import('./views/pages/Providers/form/ProviderPaymentAccountForm')
        )
      },
      {
        exact: true,
        path: '/:providerId/documents/create',
        component: lazy(
          () =>
            import(
              './views/pages/Providers/Details/ProviderDocuments/ProviderDocumentForm'
            )
        )
      },
      {
        exact: true,
        path: '/:providerId/documents/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Providers/Details/ProviderDocuments/ProviderDocumentForm'
            )
        )
      },
      {
        exact: true,
        path: '/:providerId/commercial-references/create',
        component: lazy(
          () =>
            import(
              './views/pages/Providers/Details/ProviderCommercialReferences/ProviderCommercialReferenceForm'
            )
        )
      },
      {
        exact: true,
        path: '/:providerId/commercial-references/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Providers/Details/ProviderCommercialReferences/ProviderCommercialReferenceForm'
            )
        )
      }
    ]
  },
  {
    path: '/opportunities/*',
    guard: RolesGuard([
      'ADMIN',
      'PRESALES_MANAGER',
      'COMMERCIAL_MANAGER',
      'PURCHASES'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(
          () => import('./views/pages/Opportunities/OpportunityList')
        )
      },
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/Opportunities/Form/OpportunityForm')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/Opportunities/Form/OpportunityForm')
        )
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Opportunities/Details/OpportunityDetails')
        )
      },
      {
        exact: true,
        path: '/:opportunityId/commercial-activities/create',
        component: lazy(
          () =>
            import('./views/pages/Opportunities/Form/CommercialActivityForm')
        )
      },
      {
        exact: true,
        path: '/:opportunityId/commercial-activities/:id/edit/:isDashboard',
        component: lazy(
          () =>
            import('./views/pages/Opportunities/Form/CommercialActivityForm')
        )
      },
      {
        exact: true,
        path: '/dashboard',
        component: lazy(
          () => import('./views/pages/Dashboard/CommercialDashboard')
        )
      },
      {
        exact: true,
        path: '/kanban',
        component: lazy(
          () => import('./views/pages/Opportunities/Kanban/Kanban')
        )
      }
    ]
  },
  {
    path: '/projects/remissions/*',
    guard: RolesGuard([
      'ADMIN',
      'PRESALES_MANAGER',
      'OPERATIONAL_MANAGER',
      'PRESALES',
      'HR_ASSISTANT'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/ProjectRemissions/ProjectRemissionList'
            )
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/ProjectRemissions/Form/ProjectRemissionForm'
            )
        )
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/ProjectRemissions/Details/ProjectRemissionDetails'
            )
        )
      }
    ]
  },
  {
    path: '/projects/*',
    guard: RolesGuard([
      'ADMIN',
      'OPERATIONAL_MANAGER',
      'COORDINATOR',
      'HR_ASSISTANT'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Projects/ProjectList'))
      },
      {
        exact: true,
        path: '/finished',
        component: lazy(() => import('./views/pages/Projects/ProjectList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Projects/Form/ProjectForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Projects/Form/ProjectForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Projects/Details/ProjectDetails')
        )
      },
      {
        exact: true,
        path: '/:projectId/services/create',
        component: lazy(
          () => import('./views/pages/Projects/Details/Services/ServiceForm')
        )
      },
      {
        exact: true,
        path: '/:projectId/services/:id/edit',
        component: lazy(
          () => import('./views/pages/Projects/Details/Services/ServiceForm')
        )
      },
      {
        exact: true,
        path: '/:projectId/services/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/Details/Services/Details/ServiceDetails'
            )
        )
      },
      {
        exact: true,
        path: '/:projectId/services/:serviceId/advances/create',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/Details/Services/Details/Advances/AdvanceForm'
            )
        )
      },
      {
        exact: true,
        path: '/:projectId/services/:serviceId/advances/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/Details/Services/Details/Advances/AdvanceForm'
            )
        )
      },
      {
        exact: true,
        path: '/:projectId/services/:serviceId/advances/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/Details/Services/Details/Advances/Details/AdvanceDetails'
            )
        )
      },
      {
        exact: true,
        path: '/:projectId/services/:serviceId/assignations/:employeeId/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/Details/Services/Details/Assignations/Form/AssignationForm'
            )
        )
      },
      {
        exact: true,
        path: '/:projectId/advances/create',
        component: lazy(
          () => import('./views/pages/Projects/Details/Advances/AdvanceForm')
        )
      },
      {
        exact: true,
        path: '/:projectId/advances/:id/edit',
        component: lazy(
          () => import('./views/pages/Projects/Details/Advances/AdvanceForm')
        )
      },
      {
        exact: true,
        path: '/:projectId/advances/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/Details/Advances/Details/AdvanceDetails'
            )
        )
      },
      {
        exact: true,
        path: ':projectId/internal-requests/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Projects/Details/InternalPurchaseRequests/InternalPurchaseRequestDetails'
            )
        )
      }
    ]
  },
  {
    path: '/presales/*',
    guard: RolesGuard([
      'ADMIN',
      'PRESALES_MANAGER',
      'PRESALES',
      'COORDINATOR',
      'OPERATIONAL_MANAGER',
      'COMMERCIAL_MANAGER'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Presales/PresaleList'))
      },
      {
        exact: true,
        path: '/on-eval',
        component: lazy(() => import('./views/pages/Presales/PresaleList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Presales/PresaleForm'))
      },
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/pages/Presales/Dashboard'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Presales/PresaleForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Presales/Details/PresaleDetails')
        )
      },
      {
        exact: true,
        path: '/offers/:presaleId/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Presales/Details/PresaleOffers/PresaleOfferDetails'
            )
        )
      },
      {
        exact: true,
        path: '/offers/:presaleId/create',
        component: lazy(
          () => import('./views/pages/Presales/Offers/PresaleOfferForm')
        )
      },
      {
        exact: true,
        path: '/offers/:presaleId/:id/edit',
        component: lazy(
          () => import('./views/pages/Presales/Offers/PresaleOfferForm')
        )
      },
      {
        exact: true,
        path: '/:presaleId/documents/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Presales/Details/PresaleDocuments/Details/PresaleDocumentDetails'
            )
        )
      },
      {
        exact: true,
        path: '/:presaleId/documents/create',
        component: lazy(
          () =>
            import(
              './views/pages/Presales/Details/PresaleDocuments/PresaleDocumentForm'
            )
        )
      },
      {
        exact: true,
        path: '/:presaleId/documents/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Presales/Details/PresaleDocuments/PresaleDocumentForm'
            )
        )
      }
    ]
  },
  {
    path: '/documents/*',
    guard: RolesGuard([
      'ADMIN',
      'HR',
      'OPERATIONAL_MANAGER',
      'COORDINATOR',
      'COMMERCIAL_MANAGER',
      'PRESALES_MANAGER',
      'PRESALES',
      'FINANCIAL',
      'PURCHASES',
      'HR_ASSISTANT'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Documents/DocumentList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Documents/DocumentForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Documents/DocumentForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Documents/Details/DocumentDetails')
        )
      }
    ]
  },
  {
    path: '/purchases/reference-prices/*',
    guard: RolesGuard([
      'ADMIN',
      'FINANCIAL',
      'PURCHASES',
      'PRESALES_MANAGER',
      'PRESALES'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(
          () => import('./views/pages/Purchases/ReferencePrices/List/ItemsList')
        )
      },
      {
        exact: true,
        path: 'create',
        component: lazy(
          () => import('./views/pages/Purchases/ReferencePrices/Form/ItemForm')
        )
      },
      {
        exact: true,
        path: ':id/edit',
        component: lazy(
          () => import('./views/pages/Purchases/ReferencePrices/Form/ItemForm')
        )
      },
      {
        exact: true,
        path: ':id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/ReferencePrices/Details/ItemDetails'
            )
        )
      },
      {
        exact: true,
        path: ':itemId/providers/create',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/ReferencePrices/Form/ItemProviderForm'
            )
        )
      },
      {
        exact: true,
        path: ':itemId/providers/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/ReferencePrices/Form/ItemProviderForm'
            )
        )
      }
    ]
  },
  {
    path: '/purchases/*',
    guard: RolesGuard([
      'ADMIN',
      'FINANCIAL',
      'PURCHASES',
      'OPERATIONAL_MANAGER'
    ]),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(
          () => import('./views/pages/Purchases/List/PurchasesList')
        )
      },
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/Purchases/Form/PurchaseForm')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/Purchases/Form/PurchaseForm')
        )
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Purchases/Details/PurchaseDetails')
        )
      },
      {
        exact: true,
        path: ':purchaseId/internal-requests/create',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/InternalPurchaseRequests/InternalPurchaseRequestForm'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/internal-requests/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/InternalPurchaseRequests/InternalPurchaseRequestForm'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/internal-requests/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Details/InternalPurchaseRequests/InternalPurchaseRequestDetails'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/internal-requests/:requestId/items/create',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/InternalPurchaseRequests/InternalPurchaseRequestItemForm'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/internal-requests/:requestId/items/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/InternalPurchaseRequests/InternalPurchaseRequestItemForm'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/requests/create',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/PurchaseRequests/PurchaseRequestForm'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/requests/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/PurchaseRequests/PurchaseRequestForm'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/requests/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Details/PurchaseRequests/PurchaseRequestDetails'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/requests/:requestId/items/create',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/PurchaseRequests/PurchaseRequestItemForm'
            )
        )
      },
      {
        exact: true,
        path: ':purchaseId/requests/:requestId/items/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Purchases/Form/PurchaseRequests/PurchaseRequestItemForm'
            )
        )
      }
    ]
  },
  {
    path: '/reports/*',
    layout: MainLayout,
    guard: RolesGuard(['ADMIN']),
    routes: [
      {
        exact: true,
        path: 'assignations',
        guard: RolesGuard(['ADMIN', 'OPERATIONAL_MANAGER']),
        component: lazy(
          () =>
            import(
              './views/pages/Reports/AssignationsReports/AssignationsReports'
            )
        )
      },
      {
        exact: true,
        path: '/presales',
        component: lazy(
          () => import('./views/pages/Reports/PresalesReports/PresalesReports')
        )
      },
      {
        exact: true,
        path: '/timeoff',
        guard: RolesGuard(['ADMIN', 'OPERATIONAL_MANAGER', 'HR_ASSISTANT']),
        component: lazy(
          () => import('./views/pages/Reports/TimeoffReports/TimeoffReports')
        )
      }
    ]
  },
  {
    path: '/inventories/*',
    guard: RolesGuard(['ADMIN', 'OPERATIONAL_MANAGER', 'PURCHASES']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Inventories/InventoryList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Inventories/InventoryForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Inventories/InventoryForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Inventories/Details/InventoryDetails')
        )
      },
      {
        exact: true,
        path: '/assignation/:inventoryId/create',
        component: lazy(
          () => import('./views/pages/Inventories/InventoryAssignationForm')
        )
      },
      {
        exact: true,
        path: '/remissions',
        component: lazy(
          () =>
            import('./views/pages/Inventories/Remissions/InvetoryRemissionList')
        )
      },
      {
        exact: true,
        path: '/remissions/create',
        component: lazy(
          () =>
            import(
              './views/pages/Inventories/Remissions/form/InventoryRemissionForm'
            )
        )
      },
      {
        exact: true,
        path: '/remissions/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/Inventories/Remissions/form/InventoryRemissionForm'
            )
        )
      },
      {
        exact: true,
        path: '/remissions/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/Inventories/Remissions/Details/InvetoryRemissionDetails'
            )
        )
      }
    ]
  },
  {
    path: '/hr-requests/*',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/timeoff',
        component: lazy(
          () => import('./views/pages/HrRequests/Timeoff/TimeoffRequestList')
        )
      },
      {
        exact: true,
        path: '/timeoff/create',
        component: lazy(
          () =>
            import('./views/pages/HrRequests/Timeoff/form/TimeoffRequestForm')
        )
      },
      {
        exact: true,
        path: '/timeoff/:id/edit',
        component: lazy(
          () =>
            import('./views/pages/HrRequests/Timeoff/form/TimeoffRequestForm')
        )
      },
      {
        exact: true,
        path: '/timeoff/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/HrRequests/Timeoff/Details/TimeoffRequestDetails'
            )
        )
      },
      {
        exact: true,
        path: '/documents',
        component: lazy(
          () => import('./views/pages/HrRequests/Document/DocumentRequestList')
        )
      },
      {
        exact: true,
        path: '/documents/create',
        component: lazy(
          () =>
            import('./views/pages/HrRequests/Document/form/DocumentRequestForm')
        )
      },
      {
        exact: true,
        path: '/documents/:id/edit',
        component: lazy(
          () =>
            import('./views/pages/HrRequests/Document/form/DocumentRequestForm')
        )
      },
      {
        exact: true,
        path: '/documents/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/HrRequests/Document/Details/DocumentRequestDetails'
            )
        )
      },
      {
        exact: true,
        path: '/financial',
        component: lazy(
          () =>
            import('./views/pages/HrRequests/Financial/FinancialRequestList')
        )
      },
      {
        exact: true,
        path: '/financial/create',
        component: lazy(
          () =>
            import(
              './views/pages/HrRequests/Financial/form/FinancialRequestForm'
            )
        )
      },
      {
        exact: true,
        path: '/financial/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/HrRequests/Financial/form/FinancialRequestForm'
            )
        )
      },
      {
        exact: true,
        path: '/financial/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/HrRequests/Financial/Details/FinancialRequestDetail'
            )
        )
      }
    ]
  },
  {
    path: '/profiles/*',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Profiles/Form/ProfileForm'))
      },
      {
        exact: true,
        path: '/:tab',
        component: lazy(
          () => import('./views/pages/Profiles/Details/ProfileDetails')
        )
      }
    ]
  },
  {
    path: '/services/*',
    guard: RolesGuard(['TECHNICIAN']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Services/ServicesList'))
      },
      {
        exact: true,
        path: '/history',
        component: lazy(() => import('./views/pages/Services/ServicesList'))
      }
    ]
  }
];

const renderRoutes = (routes: RoutesType[], guard?: FC) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path ?? ''}
              element={
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes, Guard)
                  ) : (
                    <Guard>
                      <Component />
                    </Guard>
                  )}
                </Layout>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;

const AppRoutes = () => {
  useUserContext();
  return renderRoutes(routesConfig);
};

export default AppRoutes;
