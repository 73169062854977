import { Section } from '@inappsis/react-inappsis-ui';
import type { TFunction } from 'react-i18next';
import {
  accountItem,
  clientItem,
  dashboardItem,
  documentItem,
  employeeItem,
  hrRequestItem,
  inventoryItem,
  opportunityItem,
  presaleItem,
  presalesReportItem,
  projectItem,
  providerItem,
  purchaseItem,
  referencePriceItem,
  reportItem,
  serviceItem,
  timeoffReportItem,
  trainingItem
} from './LayoutItems';

export const generalSections = (t: TFunction): Section => {
  return {
    title: t('layout.sections.general'),
    items: [dashboardItem(t), accountItem(t)]
  };
};

export const hrRequestsSection = (t: TFunction): Section => {
  return {
    title: t('layout.sections.requests'),
    items: [hrRequestItem(t)]
  };
};

export const reportSection = (
  t: TFunction,
  hidePresales: boolean
): Section => ({
  title: t('layout.sections.reports'),
  items: hidePresales
    ? [reportItem(t), timeoffReportItem(t)]
    : [reportItem(t), timeoffReportItem(t), presalesReportItem(t)]
});

export const adminSection = (t: TFunction): Section => ({
  title: t('layout.sections.management'),
  items: [
    clientItem(t),
    employeeItem(t, false),
    trainingItem(t),
    providerItem(t),
    opportunityItem(t, true),
    presaleItem(t, true),
    projectItem(t, false, false),
    purchaseItem(t),
    referencePriceItem(t),
    inventoryItem(t),
    documentItem(t)
  ]
});

export const hrSection = (t: TFunction): Section => ({
  title: t('layout.sections.hr'),
  items: [employeeItem(t, false)]
});

export const coordinatorSection = (t: TFunction): Section => ({
  title: t('roles.coordinator'),
  items: [
    employeeItem(t, false),
    presaleItem(t, false),
    projectItem(t, false, true)
  ]
});

export const presaleManagerSection = (t: TFunction): Section => ({
  title: t('layout.sections.presaleManager'),
  items: [
    clientItem(t),
    employeeItem(t, true),
    opportunityItem(t, false),
    presaleItem(t, false),
    projectItem(t, true, false),
    referencePriceItem(t)
  ]
});

export const presaleSection = (t: TFunction): Section => ({
  title: t('layout.sections.presale'),
  items: [
    clientItem(t),
    employeeItem(t, true),
    presaleItem(t, true),
    projectItem(t, true, false),
    referencePriceItem(t)
  ]
});

export const technicianSection = (t: TFunction): Section => ({
  title: t('layout.sections.technician'),
  items: [employeeItem(t, true), serviceItem(t)]
});

export const financialSection = (t: TFunction): Section => ({
  title: t('roles.financial'),
  items: [
    clientItem(t),
    employeeItem(t, false),
    providerItem(t),
    purchaseItem(t)
  ]
});

export const operationalSection = (t: TFunction): Section => ({
  title: t('roles.operational_manager'),
  items: [
    employeeItem(t, false),
    presaleItem(t, false),
    projectItem(t, false, false),
    inventoryItem(t),
    purchaseItem(t)
  ]
});

export const commercialSection = (t: TFunction): Section => ({
  title: t('roles.commercial_manager'),
  items: [
    clientItem(t),
    employeeItem(t, true),
    opportunityItem(t, false),
    presaleItem(t, false)
  ]
});

export const purchaseSection = (t: TFunction): Section => ({
  title: t('roles.purchases'),
  items: [
    employeeItem(t, false),
    purchaseItem(t),
    providerItem(t),
    inventoryItem(t),
    referencePriceItem(t)
  ]
});

export const hrAssistantSection = (t: TFunction): Section => ({
  title: t('roles.hr_assistant'),
  items: [employeeItem(t, false), trainingItem(t), projectItem(t, false, false)]
});

export const documentSection = (t: TFunction): Section => ({
  title: t('documentsPage.documents'),
  items: [documentItem(t)]
});

export const timeoffReportSection = (t: TFunction): Section => ({
  title: t('layout.reportsSection.reports'),
  items: [timeoffReportItem(t)]
});
