import {
  Cog as CogIcon,
  UserCircle as UserCircleIcon
} from '@inappsis/react-inappsis-ui';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import useLogout from 'hooks/useLogout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  DEFAULT_AVATAR,
  DEFAULT_USER_NAME
} from 'shared/constant/Common.constants';
import { checkRoles } from 'shared/utils/functions';
import { rolesNotAllowToSettingTab } from 'views/pages/Profiles/utils';

export interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  currentUser?: AuthUser;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, currentUser, ...other } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logout = useLogout();
  const isAllowedToSettings =
    checkRoles(['ADMIN']) || !checkRoles(rolesNotAllowToSettingTab);

  const handleLogout = async (): Promise<void> => {
    try {
      onClose?.();
      logout();
      navigate('/');
    } catch (err) {
      // log error
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex'
        }}
      >
        <Avatar
          src={currentUser?.photo ? currentUser.photo : DEFAULT_AVATAR}
          sx={{
            height: 40,
            width: 40
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1
          }}
        >
          <Typography variant="body1">
            {currentUser?.first_name
              ? currentUser?.first_name
              : DEFAULT_USER_NAME}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Inappsis
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <MenuItem component={RouterLink} to="/profiles/details">
          <ListItemIcon>
            <UserCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {t('layout.general.profile')}
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={`/profiles/${isAllowedToSettings ? 'settings' : 'details'}`}
        >
          <ListItemIcon>
            <CogIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {t('layout.general.settings')}
              </Typography>
            }
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {t('layout.general.logout')}
              </Typography>
            }
          />
        </MenuItem>
      </Box>
    </Popover>
  );
};

export default AccountPopover;
