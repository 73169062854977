import dayjs from 'dayjs';
import 'dayjs/locale/es';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './shared/translations/english.json';
import spanishTranslations from './shared/translations/spanish.json';

const resources = {
  en: {
    translation: englishTranslations
  },
  es: {
    translation: spanishTranslations
  }
};

dayjs.extend(relativeTime, {
  rounding: Math.floor,
  thresholds: [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y' },
    { l: 'yy', d: 'year' }
  ]
});
dayjs.extend(duration);
dayjs.locale('es');

use(initReactI18next).init({
  resources,
  lng: 'es',
  fallbackLng: 'es',
  returnNull: false,
  interpolation: {
    escapeValue: false
  }
});
