import { styled } from '@mui/material/styles';

interface LogoProps {
  variant?: 'light' | 'primary';
}

export const Logo = styled((props: LogoProps) => {
  const { variant, ...other } = props;

  let logoPath = `${process.env.PUBLIC_URL}/icomm_logo.png`;
  if (variant && variant === 'light') {
    logoPath = `${process.env.PUBLIC_URL}/icomm_white_logo.png`;
  }

  return <img {...other} src={logoPath} />;
})``;
